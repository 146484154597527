var render = function render(){var _vm=this,_c=_vm._self._c;return _c('yt-live-chat-ticker-renderer',{attrs:{"hidden":_vm.showMessages.length === 0}},[_c('div',{staticClass:"style-scope yt-live-chat-ticker-renderer",attrs:{"id":"container","dir":"ltr"}},[_c('transition-group',{staticClass:"style-scope yt-live-chat-ticker-renderer",attrs:{"tag":"div","css":false,"id":"items"},on:{"enter":_vm.onTickerItemEnter,"leave":_vm.onTickerItemLeave}},_vm._l((_vm.showMessages),function(message){return _c('yt-live-chat-ticker-paid-message-item-renderer',{key:message.raw.id,staticClass:"style-scope yt-live-chat-ticker-renderer",staticStyle:{"overflow":"hidden"},style:({
          '--yt-live-chat-ticker-item-primary-color': message.bgColor.primaryColor,
          '--yt-live-chat-ticker-item-secondary-color': message.bgColor.secondaryColor
        }),attrs:{"tabindex":"0","privilegeType":message.raw.type == _vm.MESSAGE_TYPE_MEMBER ? message.raw.privilegeType : '',"type":message.raw.type,"price":message.raw.price,"giftName":message.raw.giftName},on:{"click":function($event){return _vm.onItemClick(message.raw)}}},[_c('div',{staticClass:"style-scope yt-live-chat-ticker-paid-message-item-renderer",style:({
          background: message.formatBgColor,
        }),attrs:{"id":"container","dir":"ltr"}},[_c('div',{staticClass:"style-scope yt-live-chat-ticker-paid-message-item-renderer",style:({
            color: message.color
          }),attrs:{"id":"content","type":message.raw.type === _vm.MESSAGE_TYPE_MEMBER ? _vm.MESSAGE_TYPE_MEMBER : _vm.MESSAGE_TYPE_SUPER_CHAT}},[_c('img-shadow',{staticClass:"style-scope yt-live-chat-ticker-paid-message-item-renderer",attrs:{"id":"author-photo","height":"24","width":"24","imgUrl":message.raw.avatarUrl}}),(message.raw.giftName !== undefined)?_c('span',{staticClass:"style-scope yt-live-chat-ticker-paid-message-item-renderer",attrs:{"id":"text","dir":"ltr"}},[_vm._v(_vm._s(message.raw.giftName +"x"+ message.raw.num))]):_vm._e(),(message.raw.giftName == undefined)?_c('span',{staticClass:"style-scope yt-live-chat-ticker-paid-message-item-renderer",attrs:{"id":"text","dir":"ltr"}},[_vm._v(_vm._s(message.text))]):_vm._e()],1)])])}),1)],1),(_vm.pinnedMessage)?[(_vm.pinnedMessage.type === _vm.MESSAGE_TYPE_MEMBER)?_c('membership-item',{key:_vm.pinnedMessage.id,staticClass:"style-scope yt-live-chat-ticker-renderer",attrs:{"avatarUrl":_vm.pinnedMessage.avatarUrl,"authorName":_vm.getShowAuthorName(_vm.pinnedMessage),"privilegeType":_vm.pinnedMessage.privilegeType,"title":_vm.pinnedMessage.title,"time":_vm.pinnedMessage.time}}):_c('paid-message',{key:_vm.pinnedMessage.id,staticClass:"style-scope yt-live-chat-ticker-renderer",attrs:{"price":_vm.pinnedMessage.price,"avatarUrl":_vm.pinnedMessage.avatarUrl,"authorName":_vm.getShowAuthorName(_vm.pinnedMessage),"time":_vm.pinnedMessage.time,"content":_vm.pinnedMessageShowContent}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }